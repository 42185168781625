import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import routes from "./routes";
import "./App.scss";
import "./assets/css/bootstrap/style.scss";
import { SnackbarProvider } from "notistack";
import { organisationConfigurationDataService } from "./services/organisation";
import { createContext, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { createTheme } from "@mui/material";
import { generateColorPalette } from "./utilities";
import groupPhoto from "./assets/img/group-photo.png";

export const ThemeContext = createContext({name: "", logo: ""});
const App = () => {
  const { getOrganisationThemeConfiguration } = organisationConfigurationDataService();

  const organisationConfiguration = useQuery({
    queryKey: ["organisation-theme-configuration"],
    queryFn: () => getOrganisationThemeConfiguration(),
  });

  const orgThemeData = organisationConfiguration && organisationConfiguration.data && organisationConfiguration.data.data;
  const logo = orgThemeData && orgThemeData.logo && String(process.env.REACT_APP_S3_BASE_URL) + orgThemeData.logo || "";
  const name = orgThemeData && orgThemeData.name || "";
  useEffect(() => {
    if (orgThemeData) {
      const favicon = document.getElementById("favicon");
      if (favicon) {
        favicon.setAttribute("href", `${process.env.REACT_APP_S3_BASE_URL}${orgThemeData.favicon}`);
      }
    }
  }, [orgThemeData]);

  const theme = createTheme({
    typography: {
      fontFamily: orgThemeData && orgThemeData.fontFamily || "sans-serif",
    },
    palette: {
      primary: generateColorPalette(
        orgThemeData && orgThemeData.primaryColor || "#ffffff"
      ),
      secondary: generateColorPalette(
        orgThemeData && orgThemeData.secondaryColor || "#ffffff"
      ),
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
        styleOverrides: {
          root: {
            width: "100%",
            fontFamily: orgThemeData && orgThemeData.fontFamily || "sans-serif",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "medium",
          variant: "contained",
        },
        styleOverrides: {
          root: {
            fontFamily: "Poppins",
          },
        },
      },
    },
  });
  const dynamicTheme = {
    "--primary-color": orgThemeData && orgThemeData.primaryColor,
    "--font-family": orgThemeData && orgThemeData.fontFamily,
    "--banner-image": orgThemeData?.bannerImage
      ? `url(${orgThemeData.bannerImage.includes("https://")
        ? orgThemeData.bannerImage
        : `${process.env.REACT_APP_S3_BASE_URL}${orgThemeData.bannerImage}`
      })`
      : `url(${groupPhoto})`,
  } as React.CSSProperties;
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
      >

        <ThemeContext.Provider value={{name, logo}}>
          <div style={dynamicTheme}>
            <RouterProvider router={routes} />
          </div>
        </ThemeContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
